import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18next
  .use(LanguageDetector)
  .init({
    resources: {
      el: {
        common: require('../locales/el/common.el.json'),
        "new-volunteer-capture": require('../locales/el/new-volunteer-capture.el.json'),
        menu: require('../locales/el/menu.el.json')
      },
      en: {
        common: require('../locales/en/common.en.json'),
        "new-volunteer-capture": require('../locales/en/new-volunteer-capture.en.json'),
        menu: require('../locales/en/menu.en.json')
      }
    },
    ns: ["new-volunteer-capture",'menu'],
    defaultNS: 'common',
    returnObjects: true,
    debug: process.env.NODE_ENV === 'development',
    interpolation: {
      escapeValue: false, // not needed for react
    },
    detection: {
      order: ['querystring', 'cookie'],
      lookupQuerystring: 'lang',
      lookupCookie: 'lang',

      // cache user language on
      caches: ['localStorage', 'cookie'],
      excludeCacheFor: ['cimode'],

      // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
      cookieOptions: { path: '/', sameSite: 'strict' }
    },
    react: {
      wait: true
    },
    preload: true,
    fallbackLng: 'el'
  }
);

i18next.languages = ['el', 'en'];

export default i18next;