/**
 * PrimaryMenu.js - Primary menu component.
 *
 * @author Alex Symeonidis
 */
import React from 'react';
import { useTranslation } from "react-i18next";
import { Button, Form, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { Link } from 'gatsby';

const MenuItem = (props) => {
  const { t } = useTranslation("menu");

  let componentId = props.labelCode.split('.').join('-');

  return (
    <Link id={componentId} to={props.linkTo} className={"nav-link"} activeClassName={"active"}>{t(props.labelCode)}</Link>
  );
}

const PrimaryMenu = () => {
  const { t } = useTranslation("menu");

  return (
    <Navbar className="primary-menu" expand="md">
      <Navbar.Brand><img src='logo.png' width='60' height='60'/></Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
      <Navbar.Collapse id='primary-menu'>
        <Nav className={"mr-auto"}>
          <MenuItem linkTo={"/"} labelCode={"menu.main.home"}/>
          <NavDropdown title={t('menu.main.about-us')} id="nav-dropdown">
            <NavDropdown.Item href="/our-history">{t('menu.main.our-history')}</NavDropdown.Item>
            <NavDropdown.Item href="/our-team">{t('menu.main.our-team')}</NavDropdown.Item>
            <NavDropdown.Item href="/our-activities">{t('menu.main.our-activities')}</NavDropdown.Item>
          </NavDropdown>
          <MenuItem linkTo={"/volunteer-profiles"} labelCode={"menu.main.volunteer-profiles"}/>
          <NavDropdown title={t('menu.main.get-involved')} id="nav-dropdown">
            {/*
            <NavDropdown.Item href="/for-schools">{t('menu.main.for-schools')}</NavDropdown.Item>
            */}
            <NavDropdown.Item href="/for-educators">{t('menu.main.for-educators')}</NavDropdown.Item>
            <NavDropdown.Item href="/for-volunteers">{t('menu.main.for-volunteers')}</NavDropdown.Item>
          </NavDropdown>
          <MenuItem linkTo={"/faq"} labelCode={"menu.main.faq"}/>
        </Nav>
        <Form inline>
          <Button variant="outline-info" className='dashboard-button' href={"/dashboard"}>{t("menu.main.dashboard")}</Button>
        </Form>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default PrimaryMenu;